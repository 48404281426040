import React from "react"
import { css } from "@emotion/core"

type StackProps = {
  space?: string
  splitAfter?: number
  align?: string
  justify?: string
} & React.HTMLAttributes<HTMLDivElement>

const Stack = React.forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const { space, splitAfter, align, justify, children, ...htmlProps } = props
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: ${align};
          justify-content: ${justify};
          & > * {
            margin-top: 0;
            margin-bottom: 0;
          }
          & > * + * {
            margin-top: ${space};
          }
          &:only-child {
            height: 100%;
          }
          & > :nth-child(${splitAfter}) {
            margin-bottom: auto;
          }
        `,
      ]}
    >
      {children}
    </div>
  )
})

Stack.defaultProps = {
  splitAfter: 0,
  space: "0px",
}

export { Stack }
