import * as React from "react"
import { css } from "@emotion/core"

type ClusterProps = {
  space: string
  align?: string
  justify?: string
} & React.HTMLAttributes<HTMLDivElement>

const Cluster = React.forwardRef<HTMLDivElement, ClusterProps>((props, ref) => {
  const { space, align, justify, children, ...htmlProps } = props
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          overflow: hidden;
          & > * {
            display: flex;
            flex-wrap: wrap;
            justify-content: ${justify};
            align-items: ${align};
            margin: calc(${space} / 2 * -1);
          }

          & > * > * {
            margin: calc(${space} / 2);
          }
        `,
      ]}
    >
      <div>{children}</div>
    </div>
  )
})

Cluster.defaultProps = {
  justify: "flex-start",
  align: "center",
}

export { Cluster }
