import React from "react"
import { css } from "@emotion/core"

type BoxProps = {
  padding?: string
  borderWidth?: number
  backgroundColor?: string
  borderColor?: string
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>

const Box = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const {
    padding,
    borderWidth,
    backgroundColor,
    children,
    borderColor,
    ...htmlProps
  } = props
  return (
    <div
      ref={ref}
      css={css`
        padding: ${padding};
        border: ${borderWidth} solid ${borderColor};
        background-color: ${backgroundColor};
      `}
      {...htmlProps}
    >
      {children}
    </div>
  )
})

Box.defaultProps = {
  padding: "0px",
  borderWidth: 0,
  backgroundColor: "inherit",
  borderColor: "black",
}

export { Box }
