import React from "react"
import { css } from "@emotion/core"

type SwitcherProps = {
  space: string
  max: number
  threshold: string
} & React.HTMLAttributes<HTMLDivElement>

const Switcher = React.forwardRef<HTMLDivElement, SwitcherProps>(
  (props, ref) => {
    const { space, max, threshold, ...htmlProps } = props
    return (
      <div
        {...htmlProps}
        ref={ref}
        css={[
          css`
            & > * {
              display: flex;
              flex-wrap: wrap;
              margin: calc((${space} / 2) * -1);
            }

            & > * > * {
              flex-grow: 1;
              flex-basis: calc((${threshold} - (100% - ${space})) * 999);
              margin: calc(${space} / 2);
            }

            & > * > :nth-last-child(n + ${max + 1}),
            > * > :nth-last-child(n + ${max + 1}) ~ * {
              flex-basis: 100%;
            }
          `,
        ]}
      >
        <div>{props.children}</div>
      </div>
    )
  }
)

export { Switcher }
