import { Button as AntButton } from "antd"
import styled from "@emotion/styled"

export const PrimaryButton = styled(AntButton)`
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-size: var(--p);
  height: 50px;
  color: white;
  border-radius: 5px;

  &:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
  }
`

export const DefaultButton = styled(AntButton)`
  background-color: white;
  border-color: var(--primary-color);
  font-size: var(--p);
  height: 50px;
  color: var(--primary-color);
  border-radius: 5px;

  &:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
`
