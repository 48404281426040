import React from "react"
import { css } from "@emotion/core"

type FrameProps = {
  numerator: number
  denominator: number
} & React.HTMLAttributes<HTMLDivElement>

const Frame = React.forwardRef<HTMLDivElement, FrameProps>((props, ref) => {
  const { numerator, denominator, children, ...htmlProps } = props
  return (
    <div
      ref={ref}
      css={[
        css`
          --n: ${numerator};
          --d: ${denominator};
          padding-bottom: calc(var(--n) / var(--d) * 100%);
          position: relative;

          & > * {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & > img,
          & > video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `,
      ]}
      {...htmlProps}
    >
      {children}
    </div>
  )
})

export { Frame }
