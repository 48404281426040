import React, { FC } from "react"
import { css } from "@emotion/core"

type GripProps = React.HTMLAttributes<HTMLDivElement> & {
  gap: string
  min: string
}
const Grid: FC<GripProps> = props => {
  const { gap, min, ...restProps } = props
  return (
    <div
      role="grid"
      css={[
        css`
          display: grid;
          grid-gap: ${gap};
          @supports (width: min(${min}, 100%)) {
            grid-template-columns: repeat(
              auto-fill,
              minmax(min(${min}, 100%), 1fr)
            );
          }
        `,
      ]}
      {...restProps}
    />
  )
}

export { Grid }
