import React from "react"
import { css } from "@emotion/core"

type CenterProps = {
  maxWidth: string
  gutters?: string
  intrinsic?: boolean
  andText?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Center = React.forwardRef<HTMLDivElement, CenterProps>((props, ref) => {
  const {
    gutters,
    maxWidth,
    intrinsic,
    children,
    andText,
    ...htmlProps
  } = props
  return (
    <div
      {...htmlProps}
      ref={ref}
      css={[
        css`
          box-sizing: content-box;
          margin-left: auto;
          margin-right: auto;
          max-width: ${maxWidth};
          padding-left: ${gutters};
          padding-right: ${gutters};
        `,
        intrinsic &&
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `,
        andText &&
          css`
            text-align: center;
          `,
      ]}
    >
      {children}
    </div>
  )
})

Center.defaultProps = {
  gutters: "0px",
  intrinsic: false,
  andText: false,
}

export { Center }
