import React from "react"
import { css } from "@emotion/core"

const variants = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  P: "p",
  LABEL: "label",
}

const variantClassMapping = {
  [variants.H1]: css`
    font-family: var(--heading-font-bold);
    font-size: var(--h1);
    line-height: 3.25rem;
  `,
  [variants.H2]: css`
    font-family: var(--heading-font-bold);
    font-size: var(--h2);
  `,
  [variants.H3]: css`
    font-family: var(--heading-font-semi-bold);
    font-size: var(--h3);
  `,
  [variants.H4]: css`
    font-family: var(--heading-font-medium);
    font-size: var(--h4);
  `,
  [variants.H5]: css`
    font-family: inherit;
    font-size: var(--h5);
  `,
  [variants.P]: css`
    font-family: inherit;
    font-size: var(--p);
  `,
  [variants.LABEL]: css`
    font-family: inherit;
    font-size: var(--label);
  `,
}

type TextProps = {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "label"
  color?: "dark" | "light",
  as?: "h1" | "h2" | "h3" | "h4" | "p"
} & { children: React.ReactNode } & React.HTMLAttributes<HTMLParagraphElement>

const Text = React.forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const { variant, color, as: Element, ...paragraphProps } = props
  return (
    <Element
      {...paragraphProps}
      ref={ref}
      css={[
        variantClassMapping[variant],
        css`
          color: ${color === "dark"
            ? "var(--black-color)"
            : "var(--gray-color)"};
        `,
      ]}
    />
  )
})

Text.defaultProps = {
  color: "dark",
  as: "p"
}

export { Text }
